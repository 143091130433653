
import { MdHomeFilled } from "react-icons/md";
import { AiFillAppstore } from "react-icons/ai";
import { MdAddBox } from "react-icons/md";
import { IoMdHelpCircle } from "react-icons/io";
import { IoMdSettings } from "react-icons/io";

//role = superadmin, masteradmin and admin
export const routes1 = [
  {
    path: "/frontend/home",
    name: "Home",
    icon: <MdHomeFilled />,
  },
  {
    path: "/frontend/apps",
    name: "Apps",
    icon: <AiFillAppstore />,
  },
  {
    path: "/frontend/invite",
    name: "Invite",
    icon: <MdAddBox />,
  }
];

//role = users
export const routes2 = [
  {
    path: "/frontend/home",
    name: "Home",
    icon: <MdHomeFilled />,
  },
  {
    path: "/frontend/apps",
    name: "Apps",
    icon: <AiFillAppstore />,
  },
];

//role = masteradmin
export const otherroutes1 = [
  {
    path: "/frontend/settings",
    name: "Setting",
    icon: <IoMdSettings />,
  },
  {
    path: "/frontend/help",
    name: "Help",
    icon: <IoMdHelpCircle />,
  },
];

//role = users
export const otherroutes2 = [
  // {
  //   path: "/frontend/settings",
  //   name: "Setting",
  //   icon: <IoMdSettings />,
  // },
  // {
  //   path: "/frontend/help",
  //   name: "Help",
  //   icon: <IoMdHelpCircle />,
  // },
];

