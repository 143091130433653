//-----------------------Login API----------------------------//
export const frontendlocalhrms = "http://localhost:3001";
export const frontendlocalklms = "http://localhost:3002";
export const frontendlocaldms = "http://localhost:3003";

export const frontendproductionhrms = "https://hrms.vliv.app";
export const frontendproductionklms = "https://klms.vliv.app";
export const frontendproductiondms = "https://dms.vliv.app";

export const backendlocalhrms = "http://localhost:6002";
export const backendlocalklms = "http://localhost:6003";

export const backendproductionhrms = "https://hrms.vliv.app";
export const backendproductionklms = "https://klms.vliv.app";

export const vlivBackendApi = "https://vliv.app";

export const adminLogin = "https://vliv.app/user/loginuser"
// Log in USER: [POST] (Req.body = email, password)

export const adminAllCompanylist = "https://vliv.app/company/getcompany"

export const adminUpdateCompanydashboard = "https://vliv.app/company/updateaccess"
//Update Company = [POST]  (Req.body = companyarray)

export const companySignup = "https://vliv.app/company/registercompany";

export const companyLogin = "https://vliv.app/company/loginclient";

export const updateProfile = "https://vliv.app/company/profileupdate";

export const companyUserAdd = "https://vliv.app/user/adduser";

export const companyUserGet = "https://vliv.app/user/getusers";
//All Users: [POST]  (Req.body = companyemail, companyid)

export const companyUserUpdate = 'https://vliv.app/user/updateuser';

export const companyUserDelete = "https://vliv.app/user/deleteuser";
//Delete Users: [POST]  (Req.body = idarray)

export const companyManagerGet = "https://vliv.app/user/getmanager";
// Get manager =  [POST]  (Req.body = companyid, companyemail)

export const companyManagerAdd = "https://vliv.app/user/addmanager";
// Add manager =  [POST]  (Req.body = reportmanager, companyid, companyemail)

export const companyManagerDelete = "https://vliv.app/user/deletemanager";
// Delete manager =  [POST] (Req.body = idarray)

export const companyRoleget = "https://vliv.app/user/getrole";
// Get role =  [POST]  (Req.body = companyid, companyemail)

export const companyRoleAdd = "https://vliv.app/user/addrole";
// Add role =  [POST] (Req.body = userrole, companyid, companyemail)

export const companyRoleDelete = "https://vliv.app/user/deleterole";
// Delete role =  [POST] (Req.body = idarray)

export const companyProfileGet = "https://vliv.app/user/getprofile";
// Get profile =  [POST] (Req.body = companyid, companyemail)

export const companyProfileAdd = "https://vliv.app/user/addprofile";
// Add profile =  [POST] (Req.body = userprofile, companyid, companyemail)

export const companyProfileDelete = "https://vliv.app/user/deleteprofile";
// Delete profile =  [POST] (Req.body = idarray)

export const plansorderId = "https://vliv.app/company/getorderid";

export const partnershipAPI = "https://vliv.app/company/addpartnership"